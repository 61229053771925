import React from 'react'
import { Link } from 'gatsby'
const NewsItem = ({ image, title, content, link }) => {
    return (
        <div className="services__item col-md-3">
            <img className="services__item__icon" src={image} />
            <h3 className="services__item__title">
                {title}
            </h3>
            <p className="services__item__content">
                {content}
            </p>
            <Link to={link} >View more</Link>

        </div>
    )

}

export default NewsItem