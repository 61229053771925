import React from 'react';

const TitleBlock = ({ title, subtitle }) => {
    return (
        <>
            <div className="title__block">
                <div className="container">
                    <h1 className="title__block__title">{title}</h1>
                    <span className="title__block__sub__title">{subtitle}</span>
                </div>
            </div>
        </>
    )

}

export default TitleBlock