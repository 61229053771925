import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block/title-block"
import NewsItem from "../components/news/news"
const NewsPage = () => (
  <Layout>
    <SEO title="News" />
    <TitleBlock title="News" subtitle="" />
    <div className="container">
      <h2>
        News &amp; Updates
    </h2>
      <p>Stay up to date with latest news and industry trends</p>
      <div className="row">
        <NewsItem title={'News Item 1'} content={'Adipiscing elit, sed do eiusmod tempor inciunt ut labore et dolore magna liqua.abore et dolore incididunt ut labore et dolore magna liqua. abore et dolore'} />
        <NewsItem title={'News Item 2'} content={'Adipiscing elit, sed do eiusmod tempor inciunt ut labore et dolore magna liqua.abore et dolore incididunt ut labore et dolore magna liqua. abore et dolore'} />
        <NewsItem title={'News Item 3'} content={'Adipiscing elit, sed do eiusmod tempor inciunt ut labore et dolore magna liqua.abore et dolore incididunt ut labore et dolore magna liqua. abore et dolore'} />
        <NewsItem title={'News Item 4'} content={'Adipiscing elit, sed do eiusmod tempor inciunt ut labore et dolore magna liqua.abore et dolore incididunt ut labore et dolore magna liqua. abore et dolore'} />  </div>
    </div>
  </Layout>
)

export default NewsPage
